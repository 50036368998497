//
//
//
//
//
//

import { mapMutations } from 'vuex'
import { CurtainTransitionBasic } from '~/assets/js/transitions.js'
import PostList from '~/components/posts/PostList'
import PostContent from '~/components/posts/PostContent'
export default {
	mixins: [ CurtainTransitionBasic ],
	
	colorMode: 'light', 
	
	components: {
		PostList,PostContent
	},

	async asyncData({ $axios, store, params, app }) {
		const page = await $axios.$get('wp/v2/front-page',{
			params: {lang:app.i18n.locale}
		})
		store.commit('CURRENT_POST_UPDATE', page)
		store.commit('FILTER_BY_CAT', null)
	},

	head () {
		return {
			bodyAttrs: {
				class: 'home'
			}
		}
	}
}
