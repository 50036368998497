import { TimelineMax, TweenMax, Power2, Back } from 'gsap'

let curtainsOpenTimeline = () => {
  return new TimelineMax()
    .set('.transition', { y:0})
    // .fromTo('h2', 0.6, { x: 0 }, { x: 50, ease: Power2.easeInOut })
    // .to('.content', .6, { scale: .5, opacity : .5 , ease: Power2.easeInOut })
    .fromTo('.transition', .6, { height: 0  }, { height: '100vh' , ease: Power2.easeInOut })
    // .fromTo('.NuxtLogo', 0.3, { x: '100%', autoAlpha: 1 }, { x: '-100%', autoAlpha: 0, ease: Power2.easeInOut }, '-=0.45')
}

let curtainsCloseTimeline = () => {
  return new TimelineMax()
    // .set('.content', { scale: .5, opacity: 0 })
    // .fromTo('h2', 0.6, { x: 50 },{ x: 0, ease: Power2.easeInOut }, '-=0.5')
    .fromTo('.transition', .6, { height: '100vh', y: 0 }, { height: 0, y: '100vh', ease: Power2.easeInOut })
    // .to('.content', .9, { scale: 1 , opacity:1, ease: Power2.easeInOut })
    // .fromTo('.NuxtLogo', 0.3, { x: '-100%', autoAlpha: 0 }, { x: '0%', autoAlpha: 1, ease: Power2.easeInOut }, '-=0.5')
}

let caseStudyReveal = () => {
  return new TimelineMax()
    .staggerTo('.st', 0.3, { autoAlpha: 1, y: '0%', ease: Back.easeOut.config(3.7) }, 0.05, '-=0.2')
    .fromTo('.project-header-year', 0.3, { autoAlpha: 0, y: '20%' }, { autoAlpha: 1, y: '0%' }, '-=0.6')
}

let projectsHeroReveal = () => {
  return TweenMax.staggerTo('.st', 0.3, { autoAlpha: 1, y: '0%', ease: Back.easeOut.config(3.7) }, 0.05)
}

let hideStaggers = () => {
  return TweenMax
    .set('.st', { autoAlpha: 0, y: '-18px' })
}

const createTransitionMixin = (leaveTweens = [], enterTweens = []) => {
  return {
    transition: {
      css: false,
      mode: 'out-in',
      leave (el, done) {
        if (!this.$store.state.menuIsAnimating) {
          let leaveTimeline = new TimelineMax()
          leaveTweens.forEach(tween => {
            leaveTimeline.add(tween())
          })
          leaveTimeline.eventCallback('onComplete', done)
        } else {
          done()
          console.log('leave done ')
        }
      },
      enter (el, done) {
        if (!this.$store.state.menuIsAnimating) {
          let enterTimeline = new TimelineMax()
          enterTweens.forEach(tween => {
            enterTimeline.add(tween())
          })
          enterTimeline.eventCallback('onComplete', done)
        } else {
          done()
          console.log('enter done ')
        }
      }
    }
  }
}

export let CurtainTransitionBasic = createTransitionMixin(
  [ curtainsOpenTimeline ],
  [ curtainsCloseTimeline ]
)

export let CurtainTransitionCaseStudy = createTransitionMixin(
  [ curtainsOpenTimeline ],
  [ curtainsCloseTimeline, hideStaggers, caseStudyReveal ]
)

export let CurtainTransitionProjectList = createTransitionMixin(
  [ curtainsOpenTimeline ],
  [ curtainsCloseTimeline, hideStaggers, projectsHeroReveal ]
)